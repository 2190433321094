// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---docs-404-md": () => import("../../docs/404.md" /* webpackChunkName: "component---docs-404-md" */),
  "component---docs-index-mdx": () => import("../../docs/index.mdx" /* webpackChunkName: "component---docs-index-mdx" */),
  "component---docs-about-accessibility-statement-md": () => import("../../docs/about/accessibility-statement.md" /* webpackChunkName: "component---docs-about-accessibility-statement-md" */),
  "component---docs-about-contribute-md": () => import("../../docs/about/contribute.md" /* webpackChunkName: "component---docs-about-contribute-md" */),
  "component---docs-apis-geoserver-md": () => import("../../docs/apis/geoserver.md" /* webpackChunkName: "component---docs-apis-geoserver-md" */),
  "component---docs-apis-helerm-md": () => import("../../docs/apis/helerm.md" /* webpackChunkName: "component---docs-apis-helerm-md" */),
  "component---docs-apis-infofinland-md": () => import("../../docs/apis/infofinland.md" /* webpackChunkName: "component---docs-apis-infofinland-md" */),
  "component---docs-apis-issuereporting-redirect-md": () => import("../../docs/apis/issuereporting-redirect.md" /* webpackChunkName: "component---docs-apis-issuereporting-redirect-md" */),
  "component---docs-apis-kore-md": () => import("../../docs/apis/kore.md" /* webpackChunkName: "component---docs-apis-kore-md" */),
  "component---docs-apis-linked-events-redirect-md": () => import("../../docs/apis/linked-events-redirect.md" /* webpackChunkName: "component---docs-apis-linked-events-redirect-md" */),
  "component---docs-apis-linkedevents-md": () => import("../../docs/apis/linkedevents.md" /* webpackChunkName: "component---docs-apis-linkedevents-md" */),
  "component---docs-apis-openahjo-md": () => import("../../docs/apis/openahjo.md" /* webpackChunkName: "component---docs-apis-openahjo-md" */),
  "component---docs-apis-respa-md": () => import("../../docs/apis/respa.md" /* webpackChunkName: "component---docs-apis-respa-md" */),
  "component---docs-apis-servicemap-redirect-md": () => import("../../docs/apis/servicemap-redirect.md" /* webpackChunkName: "component---docs-apis-servicemap-redirect-md" */),
  "component---docs-apis-servicemap-md": () => import("../../docs/apis/servicemap.md" /* webpackChunkName: "component---docs-apis-servicemap-md" */),
  "component---docs-apis-snowplows-md": () => import("../../docs/apis/snowplows.md" /* webpackChunkName: "component---docs-apis-snowplows-md" */),
  "component---docs-apis-statistics-md": () => import("../../docs/apis/statistics.md" /* webpackChunkName: "component---docs-apis-statistics-md" */),
  "component---docs-best-practices-accepting-contributions-md": () => import("../../docs/best-practices/accepting-contributions.md" /* webpackChunkName: "component---docs-best-practices-accepting-contributions-md" */),
  "component---docs-best-practices-authentication-md": () => import("../../docs/best-practices/authentication.md" /* webpackChunkName: "component---docs-best-practices-authentication-md" */),
  "component---docs-best-practices-django-configuration-md": () => import("../../docs/best-practices/django-configuration.md" /* webpackChunkName: "component---docs-best-practices-django-configuration-md" */),
  "component---docs-best-practices-documentation-md": () => import("../../docs/best-practices/documentation.md" /* webpackChunkName: "component---docs-best-practices-documentation-md" */),
  "component---docs-best-practices-licensing-md": () => import("../../docs/best-practices/licensing.md" /* webpackChunkName: "component---docs-best-practices-licensing-md" */),
  "component---docs-best-practices-scrum-project-management-md": () => import("../../docs/best-practices/scrum-project-management.md" /* webpackChunkName: "component---docs-best-practices-scrum-project-management-md" */),
  "component---docs-best-practices-supported-browsers-md": () => import("../../docs/best-practices/supported-browsers.md" /* webpackChunkName: "component---docs-best-practices-supported-browsers-md" */),
  "component---docs-best-practices-technology-choices-md": () => import("../../docs/best-practices/technology-choices.md" /* webpackChunkName: "component---docs-best-practices-technology-choices-md" */),
  "component---docs-best-practices-testing-requirements-md": () => import("../../docs/best-practices/testing-requirements.md" /* webpackChunkName: "component---docs-best-practices-testing-requirements-md" */),
  "component---docs-best-practices-version-control-md": () => import("../../docs/best-practices/version-control.md" /* webpackChunkName: "component---docs-best-practices-version-control-md" */),
  "component---docs-projects-decidim-helsinki-md": () => import("../../docs/projects/decidim-helsinki.md" /* webpackChunkName: "component---docs-projects-decidim-helsinki-md" */),
  "component---docs-projects-devhel-fi-md": () => import("../../docs/projects/devhel.fi.md" /* webpackChunkName: "component---docs-projects-devhel-fi-md" */),
  "component---docs-projects-geoserver-preview-md": () => import("../../docs/projects/geoserver-preview.md" /* webpackChunkName: "component---docs-projects-geoserver-preview-md" */),
  "component---docs-projects-helsinki-app-md": () => import("../../docs/projects/helsinki-app.md" /* webpackChunkName: "component---docs-projects-helsinki-app-md" */),
  "component---docs-projects-helsinki-ckan-md": () => import("../../docs/projects/helsinki-ckan.md" /* webpackChunkName: "component---docs-projects-helsinki-ckan-md" */),
  "component---docs-projects-kerro-kantasi-md": () => import("../../docs/projects/kerro-kantasi.md" /* webpackChunkName: "component---docs-projects-kerro-kantasi-md" */),
  "component---docs-projects-koulurekisteri-md": () => import("../../docs/projects/koulurekisteri.md" /* webpackChunkName: "component---docs-projects-koulurekisteri-md" */),
  "component---docs-projects-linked-courses-md": () => import("../../docs/projects/linked-courses.md" /* webpackChunkName: "component---docs-projects-linked-courses-md" */),
  "component---docs-projects-linked-events-md": () => import("../../docs/projects/linked-events.md" /* webpackChunkName: "component---docs-projects-linked-events-md" */),
  "component---docs-projects-luontotietojarjestelma-md": () => import("../../docs/projects/luontotietojarjestelma.md" /* webpackChunkName: "component---docs-projects-luontotietojarjestelma-md" */),
  "component---docs-projects-maanvuokrajarjestelma-md": () => import("../../docs/projects/maanvuokrajarjestelma.md" /* webpackChunkName: "component---docs-projects-maanvuokrajarjestelma-md" */),
  "component---docs-projects-open-city-profile-md": () => import("../../docs/projects/open-city-profile.md" /* webpackChunkName: "component---docs-projects-open-city-profile-md" */),
  "component---docs-projects-openahjo-md": () => import("../../docs/projects/openahjo.md" /* webpackChunkName: "component---docs-projects-openahjo-md" */),
  "component---docs-projects-palautebot-md": () => import("../../docs/projects/palautebot.md" /* webpackChunkName: "component---docs-projects-palautebot-md" */),
  "component---docs-projects-projects-md": () => import("../../docs/projects/projects.md" /* webpackChunkName: "component---docs-projects-projects-md" */),
  "component---docs-projects-respa-md": () => import("../../docs/projects/respa.md" /* webpackChunkName: "component---docs-projects-respa-md" */),
  "component---docs-projects-service-map-md": () => import("../../docs/projects/service-map.md" /* webpackChunkName: "component---docs-projects-service-map-md" */),
  "component---docs-projects-stadin-tilapankki-md": () => import("../../docs/projects/stadin-tilapankki.md" /* webpackChunkName: "component---docs-projects-stadin-tilapankki-md" */),
  "component---docs-projects-tiedonohjausjarjestelma-md": () => import("../../docs/projects/tiedonohjausjarjestelma.md" /* webpackChunkName: "component---docs-projects-tiedonohjausjarjestelma-md" */),
  "component---docs-projects-varaamo-md": () => import("../../docs/projects/varaamo.md" /* webpackChunkName: "component---docs-projects-varaamo-md" */),
  "component---docs-resources-django-libraries-md": () => import("../../docs/resources/django-libraries.md" /* webpackChunkName: "component---docs-resources-django-libraries-md" */),
  "component---docs-resources-frontend-utils-md": () => import("../../docs/resources/frontend-utils.md" /* webpackChunkName: "component---docs-resources-frontend-utils-md" */),
  "component---docs-best-practices-accessibility-md": () => import("../../docs/best-practices/accessibility.md" /* webpackChunkName: "component---docs-best-practices-accessibility-md" */),
  "component---docs-best-practices-coding-standards-md": () => import("../../docs/best-practices/coding-standards.md" /* webpackChunkName: "component---docs-best-practices-coding-standards-md" */),
  "component---docs-resources-maps-md": () => import("../../docs/resources/maps.md" /* webpackChunkName: "component---docs-resources-maps-md" */),
  "component---docs-apis-open-311-md": () => import("../../docs/apis/open311.md" /* webpackChunkName: "component---docs-apis-open-311-md" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

